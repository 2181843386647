<script setup>
	const { card } = defineProps({
		card: {
			type: Object,
			default: () => ({}),
		},
		attributes: {
			type: Object,
			default: () => ({}),
		},
	});
</script>

<template>
	<li v-if="card" class="two-up-card fragment">
		<MessFragmentsCard :card="card" />
	</li>
</template>

<style lang="scss">
	.two-up-card {
		--card-reveal: 350ms linear;

		transform: translate(0, 2rem);
		opacity: 0;
		transition: var(--card-reveal) 200ms;

		@media (min-width: $mobile) {
			align-self: self-start;

			&:nth-of-type(even) {
				align-self: self-end;
				transition: var(--card-reveal) 500ms;
			}
		}
	}

	.v-aos-entered {
		.two-up-card {
			transform: translate(0, 0);
			opacity: 1;
		}
	}
</style>
